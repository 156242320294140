<template>
  <div class="recipeFilterModal">
    <base-heading type="2">
      {{ $t('recipes.searchFiltersModalTitle') }}
    </base-heading>

    <div
      v-for="category in filters"
      v-bind:key="category.id"
      class="recipeFilterModal__categories"
    >
      <base-heading type="4">
        {{ category.label }}
      </base-heading>

      <a
        v-for="option in category.optionsActive"
        v-bind:key="option.value"
        v-bind:class="{
          'recipeFilterModal__optionLink': true,
          'recipeFilterModal__optionLink--active': !!selectedFilter[category.id] && selectedFilter[category.id] === option.value,
        }"
        v-on:click="selectOption(option, category)"
      >
        {{ option.name }}
      </a>

      <a
        v-if="!category.open && category.optionsInactive"
        type="secondary"
        class="recipeFilterModal__toggleLink"
        v-on:click="openCategory(category)"
      >
        + {{ $t('recipes.searchFiltersModalMoreLabel') }}
      </a>

      <a
        v-if="category.open && category.optionsInactive"
        type="secondary"
        class="recipeFilterModal__toggleLink"
        v-on:click="closeCategory(category)"
      >
        - {{ $t('recipes.searchFiltersModalLessLabel') }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BaseHeading from '@/components/BaseHeading';
import recipeFilters from '@/data/recipeFilters';

export default {
  components: {
    BaseHeading,
  },

  data() {
    return {
      filters: recipeFilters,
    };
  },

  computed: {
    ...mapState('recipe', ['selectedFilter']),
  },

  methods: {
    openCategory(category) {
      const cat = this.filters.filter(filterCategory => filterCategory.id === category.id);

      cat[0].optionsActive = cat[0].optionsActive.concat(cat[0].optionsInactive);
      cat[0].open = true;
    },

    closeCategory(category) {
      const cat = this.filters.filter(filterCategory => filterCategory.id === category.id);

      cat[0].optionsActive = cat[0].optionsActive.slice(0, 3);
      cat[0].open = false;
    },

    selectOption(option, category) {
      this.$store.commit('recipe/setSelectedFilter', {
        ...this.$store.state.recipe.selectedFilter,
        [category.id]: option.value,
      });

      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.recipeFilterModal__categories {
  margin: 0 0 40px;
}

.recipeFilterModal__optionLink {
  display: flex;
  align-items: center;
  margin: 0 -8px;
  padding: 0 8px;
  height: 32px;
  border-radius: $border-radius;
  cursor: pointer;

  &--active {
    background: $color-beige;
    font-weight: 600;
  }
}

.recipeFilterModal__toggleLink {
  cursor: pointer;
}
</style>
