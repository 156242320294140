import { i18n } from '@/plugins/i18n';

export default [
  {
    id: 'course',
    label: i18n.global.t('recipes.searchFiltersModalCourseTitle'),
    open: false,
    optionsActive: [
      {
        name: i18n.global.t('recipes.filtersCourseOntbijt'),
        value: 'ontbijt',
      },
      {
        name: i18n.global.t('recipes.filtersCourseLunch'),
        value: 'lunch',
      },
      {
        name: i18n.global.t('recipes.filtersCourseDiner'),
        value: 'diner',
      }
    ],
    optionsInactive: [
      {
        name: i18n.global.t('recipes.filtersCourseTussendoor'),
        value: 'tussendoor',
      },
      {
        name: i18n.global.t('recipes.filtersCourseSmoothie'),
        value: 'smoothie',
      }
    ]
  },
  {
    id: 'type',
    label: i18n.global.t('recipes.searchFiltersModalTypeTitle'),
    open: false,
    optionsActive: [
      {
        name: i18n.global.t('recipes.filtersTypeVega'),
        value: 'vega',
      },
      {
        name: i18n.global.t('recipes.filtersTypeVegan'),
        value: 'vegan',
      },
      {
        name: i18n.global.t('recipes.filtersTypeVis'),
        value: 'vis',
      }
    ],
    optionsInactive: [
      {
        name: i18n.global.t('recipes.filtersTypeVlees'),
        value: 'vlees',
      },
      {
        name: i18n.global.t('recipes.filtersTypeSalade'),
        value: 'salade',
      },
      {
        name: i18n.global.t('recipes.filtersTypeSoep'),
        value: 'soep',
      },
      {
        name: i18n.global.t('recipes.filtersTypePasta'),
        value: 'pasta',
      },
      {
        name: i18n.global.t('recipes.filtersTypeWokgerecht'),
        value: 'wokgerecht',
      },
      {
        name: i18n.global.t('recipes.filtersTypeBrood'),
        value: 'brood',
      },
      {
        name: i18n.global.t('recipes.filtersTypeFamilierecept'),
        value: 'familierecept',
      }
    ]
  },
  {
    id: 'special',
    label: i18n.global.t('recipes.searchFiltersModalSpecialTitle'),
    open: false,
    optionsActive: [
      {
        name: i18n.global.t('recipes.filtersSpecialGlutenvrij'),
        value: 'glutenvrij',
      },
      {
        name: i18n.global.t('recipes.filtersSpecialEiwitrijk'),
        value: 'eiwitrijk',
      },
      {
        name: i18n.global.t('recipes.filtersSpecialKoolhydraatarm'),
        value: 'koolhydraatarm',
      }
    ]
  },
  {
    id: 'program',
    label: i18n.global.t('recipes.searchFiltersModalProgramTitle'),
    open: false,
    optionsActive: [
      {
        name: 'Mark',
        value: 'mark',
      },
      {
        name: 'Carlos',
        value: 'carlos',
      },
      {
        name: 'Remy',
        value: 'remy',
      }
    ],
    optionsInactive: [
      {
        name: 'Radmilo',
        value: 'radmilo',
      },
      {
        name: 'Kasia',
        value: 'kasia',
      },
      {
        name: 'Yoga',
        value: 'yoga',
      },
      {
        name: 'Circuittraining',
        value: 'circuittraining',
      },
      {
        name: 'Yoga fit & slank',
        value: 'yogafitslank',
      },
      {
        name: 'MTV Fit',
        value: 'mtvfit',
      },
      {
        name: 'Groove',
        value: 'groove',
      },
    ],
  },
];